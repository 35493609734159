@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

html {
    scroll-behavior: smooth;
}

body {
    @apply text-base;
    @apply font-normal;
}

.wd-logo-word-mark {
    @apply fill-brand-blue;
}

.wd-logo-square-shape {
    @apply fill-brand-orange;
}

.wd-logo-word-white-mark {
    @apply fill-white;
}

.wd-logo-white-square-shape {
    @apply fill-brand-orange;
}

.container {
    @apply max-w-7xl;
    @apply mx-auto;
    @apply py-16;
    @apply px-4;
    @apply sm:px-6;
    @apply lg:px-8;
}

button,
a.link,
.hover-transition {
    @apply transition;
    @apply ease-in-out;
    @apply duration-150;
}

a.link, .markdown-container a {
    font-weight: 700;
    @apply text-brand-blue;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
    @apply border-brand-blue;
    width: 0;
}

a.error {
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
    @apply border-red-400;
}

a.link:hover {
    @apply text-brand-orange;
}

[type="checkbox"]:checked,
[type="checkbox"]:checked:hover,
[type="checkbox"]:checked:focus {
    @apply bg-brand-orange;
}

.markdown-container p {
    @apply mb-6;
}

.markdown-container ul {
    @apply my-4;
    @apply list-disc;
}

.markdown-container ul > li {
    @apply ml-6;
}

.markdown-container h2 {
    @apply mt-6;
    @apply font-bold;
    @apply text-lg;
}